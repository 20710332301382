import agent from '../../ajax/agent'
import { userManageStore } from '../../mobx';
import { message } from 'antd';

class UserManageAction {
    fetchUserList = (param) => {
        agent.get("/api/user", param)
        .then(data => {
            userManageStore.setUserList(data.data); 
        })
        .catch(e => {
            message.error(`获取用户失败${e.message}`);
            console.error(e);
        })
    }

    fetchUserActionBlock = (userId) => {
        agent.get(`/api/user/${userId}/action_block`)
        .then(data => {
            userManageStore.setUserActionBlock(data.data);
        })
        .catch(e => {
            message.error(e.message);
            console.error(e);
        })
    }

    modifyUserActionBlock = (userId, blockData) => {
        agent.post(`/api/user/${userId}/action_block`, blockData)
        .then(data => {
            userManageStore.setUserActionBlock(data.data);
        })
        .catch(e => {
            message.error(e.message);
            console.error(e);
        })
    }

    addUserRole = (userId, roleLabel) => {
        agent.post(`/api/user/${userId}/role`, {roleLabel: roleLabel})
        .then(data => {
            this.fetchUserProfile(userId);
        })
        .catch(e => {
            message.error(e.message);
            console.error(e);
        })
    } 

    deleteUserRole = (userId, roleLabel) => {
        debugger
        console.log(this);
        agent.delete(`/api/user/${userId}/role/${roleLabel}`)
        .then(data => {
            this.fetchUserProfile(userId);
        })
        .catch(e => {
            message.error(e.message);
            console.error(e);
        })
    }

    deleteUserById = (userId) => {
        agent.delete(`/api/user/${userId}`)
        .then(data => {
            userManageStore.setDeleteUserDone(true);
            message.info("删除用户成功");
        })
        .catch(e => {
            userManageStore.setDeleteUserDone(true);
            message.error(e.message);
            console.error(e);
        })
    }

    unbindUserWeixin = (userId) => {
        agent.post(`/api/weixin/${userId}/unbind`)
        .then(data => {
            userManageStore.setUserWeixinInfo(null);
        })
        .catch(e => {
            message.error(e.message);
            console.error(e);
        })
    }

    fetchActiveStatistics = (type, page, size) => {
        agent.get(`/api/statistics/day_statistics`, {type, page, size})
        .then(data => {
            userManageStore.setDayStatisticsData(data.data);
        })
        .catch(e => {
            message.error(e.message);
            console.error(e);
        })
    } 
    fetchRetentionRate = (type, page, size) => {
        agent.get(`/api/statistics/retention_rate`, {type, page, size})
        .then(data => {
            userManageStore.setDetentionRateData(data.data);
        })
        .catch(e => {
            message.error(e.message);
            console.error(e);
        })
    } 

    fetchUserProfile = (userId) => {
        Promise.all([
            agent.get(`/api/user/${userId}/profile`),
            agent.get(`/api/user/${userId}/device`),
            agent.get(`/api/user/${userId}/role`),
            agent.get(`/api/user/${userId}/permission`),
        ])
        .then(([profileData, deviceData, roleData, permissionData]) => {
            userManageStore.setUserProfile(profileData.data);
            userManageStore.setUserDevice(deviceData.data);
            userManageStore.setUserRole(roleData.data);
            userManageStore.setUserPermission(permissionData.data)
        })
        .catch(e => {
            message.error(e.message);
            console.error(e);
        })
    }
}

export default new UserManageAction();