import { observable, action } from "mobx";
import OSS from 'ali-oss';

class AliyunOssStore {
    //上传凭证，上传固件用
    @observable uploadToken = {};
    @observable uploadOss = null;

    //下载凭证，下载交易图片用
    @observable downloadToken = {};
    @observable downloadOss = null;

    @action
    setUploadToken(token) {
        this.uploadToken = token;
        this.uploadOss = new OSS({
            accessKeyId: token.accessKeyId,
            accessKeySecret: token.accessKeySecret,
            stsToken: token.securityToken,
            region: "oss-cn-shenzhen",
            secure: true,
            bucket: process.env.REACT_APP_OSS_BUCKET,
        });
    }

    @action
    setDownloadToken(token) {
        this.downloadToken = token;
        this.downloadOss = new OSS({
            accessKeyId: token.accessKeyId,
            accessKeySecret: token.accessKeySecret,
            stsToken: token.securityToken,
            region: "oss-cn-shenzhen",
            secure: true,
            bucket: process.env.REACT_APP_OSS_BUCKET,
        });
    }
}

export default AliyunOssStore;