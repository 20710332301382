import { observable, action } from "mobx";

export default class ProductStore {
    @observable
    propertyList = [];

    @observable
    targetProperty = null;

    @observable
    fetchProperty = false;

    @action
    setFetchProperty(fetch) {
        this.fetchProperty = fetch;
    }

    @action
    setPropertyList(list) {
        this.propertyList = list;
    }

    @action
    setTargetProperty(target) {
        this.targetProperty = target;
    }
}