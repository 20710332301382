import React from 'react';

import style from "./Footer.module.css"
const Footer = () =>{
    return <div className={style.footer}>
        Copyright©2018-2021 深圳七块技术有限公司 保留所有版权 <a href="http://www.miitbeian.gov.cn" target="_Blank" rel="noopener noreferrer">粤ICP备:18044098号-1</a>
    </div>
};

export default Footer;
/*
  .footer {
    width: 100%;
    height:20px;
    position:absolute;
    bottom:10px;
    left:0px;
    color: #000000;
  }
  */