import { observable, action } from "mobx";

class FirmwareStore {
    @observable firmwareListData = {};

    @observable targetFirmware = null;

    @observable composeSuccess = false;

    @observable packageSaveSuccess = false;

    @observable deleteFirmwareSuccess = false;

    @observable modifyFirmwareStateSuccess = false;
    
    @observable favoriteFirmwareType = localStorage.getItem("faverite.firmware_type") || -1;

    @action
    setFavoriteFirmwareType(type) {
        this.favoriteFirmwareType = type;
        localStorage.setItem("faverite.firmware_type", type);
    }

    @action
    setModifyFirmwareStateResult(success) {
        this.modifyFirmwareStateSuccess = success;
    }
    
    @action
    setFirmwareList(firmwareList) {
        this.firmwareListData = firmwareList;
    }

    @action
    setPackageSaveResult(success) {
        this.packageSaveSuccess = success;
    }

    @action
    setDeleteFirmwareResult(success) {
        this.deleteFirmwareSuccess = success;
    }

    @action
    setTargetFirmware(firmware) {
        this.targetFirmware = firmware;
    }

    @action
    setComposeSuccess(success) {
        this.composeSuccess = success;
    }
}

export default FirmwareStore;