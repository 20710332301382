import { observable, action } from "mobx";

class UserStore {
    @observable token = localStorage.getItem("token");
    @observable profile = {};
    @observable devices = [];
    @observable permissions = [];
    @observable roles = [];

    @action
    setToken(value) {
        console.debug("set token", value);
        if(!value) {
            localStorage.removeItem("token");
        } else {
            localStorage.setItem("token", value);
        }
        this.token = value;
    }

    get token() {
        console.debug("get token", this.token);

        return this.token;
    }

    @action
    setUserProfile(profile) {
        this.profile = profile;
    }

    @action
    setUserDevice(devices) {
        this.devices = devices;
    }

    @action
    setUserPermission(permissions) {
        this.permissions = permissions;
    }

    @action
    setUserRole(roles) {
        this.roles = roles;
    }
}   

export default UserStore;