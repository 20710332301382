import { observable, action } from "mobx";

class SystemNoticeStore {
    @observable systemNoticeData = {};

    @observable targetSystemNotice = {};

    @observable refetch = false;

    @action
    setSystemNotice(systemNoticeData) {
        this.systemNoticeData = systemNoticeData;
    }

    @action
    setTargetSystemNotice(targetSystemNotice) {
        this.targetSystemNotice = targetSystemNotice;
    }
    
    @action
    setRefetch(refetch) {
        this.refetch = refetch;
    }
}

export default SystemNoticeStore;