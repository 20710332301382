import moment from 'moment-timezone';

const Utils = {
    getCookie(name) {
        var nameEQ = name + "=";
        var ca = document.cookie.split(';');
        for(var i=0;i < ca.length;i++) {
            var c = ca[i];
            while (c.charAt(0) === ' ') c = c.substring(1,c.length);
            if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length,c.length);
        }
        return null;
    },
    isValidMobile(mobile) {
        if(!mobile) {
            return false;
        }
        const MOBILE_PREFIX = [
            //移动号段：
            "134", "135", "136", "137", "138", "139", "147", "148", "150",
            "151", "152", "157", "158", "159", "165", "172", "178", "182",
            "183", "184", "187", "188", "198",
            //联通号段：
            "130", "131", "132", "145", "146", "155", "156", "166", "171",
            "175", "176", "185", "186",
            //电信号段：
            "133", "149", "153", "173", "174", "177", "180", "181", "189",
            "191", "199",
            //虚拟运营商:
            "170",
        ];
        mobile = mobile.replace(/\s/g, "");
        if(!/^\d{11}$/.test(mobile)) {
            return false;
        }
        for(let pref of MOBILE_PREFIX) {
            if(mobile.startsWith(pref)) {
                return true;
            }
        }
        return false;
    },
    formatDateString(date, defaultString) {
        if(!date || date === '') {
            return defaultString || "-";
        }
        return moment.utc(date).local().format('YYYY-MM-DD');
    },
    formatTimeString(time, defaultString) {
        if(!time || time === '') {
            return defaultString || "-";
        }
        return moment.utc(time).local().format('YYYY-MM-DD HH:mm:ss');
    },
    parseLocationQuery (search) {
        //search = decodeURIComponent(search);
        if(!search) {
            return {};
        }
        let startQuery = search.indexOf("?");
        if(startQuery > 0) {
            search = search.substr(startQuery + 1);
        }
        let arr = search.split("&").map(s=> {
                let arr = s.split("=");
                if(arr.length !== 2) {
                return null;
                }
                const key = arr[0];
                const value = arr[1];
                let obj = {}
                obj[key] = value;
                return obj;
                }).filter(a => a !== null);
        let obj = {};
        for(let kv of arr) {
            obj = {...obj, ...kv};
        }
        return obj;
    },
    formatTimeGapFromNow(time) {
        if(!time) {
            return '-';
        }
        const time1 = moment.utc(new Date());
        const time2 = moment.utc(time);
        const year = time1.diff(time2, 'year')

        if(year > 0) {
            return `${year}年前`
        } else if(year < 0) {
            return `${year}年后`
        }
        const month = time1.diff(time2, 'month')
        if(month > 0) {
            return `${month}个月前`
        } else if(month < 0){
            return `${month}个月后`
        }
        const day = time1.diff(time2, 'day')
        if(day > 0) {
            return `${day}天前`
        } else if(day < 0) {
            return `${day}天后`
        }
        const hour = time1.diff(time2, 'hour')
        if(hour > 0) {
            return `${hour}小时前`
        } else if(hour < 0) {
            return `${hour}小时后`
        }
        const minute = time1.diff(time2, 'minute')
        if(minute > 0) {
            return `${minute}分钟前`
        } else if(minute < 0) {
            return `${minute}分钟后`
        }
        return '刚刚';
    },
    beautifyChg(chg) {
        if(!chg) {
            chg = "0.0";
        }
        let v = parseFloat(chg);
        v = v * 100;
        return v.toFixed(2) + "%";
    },
    beautifyPercentage(num, bits) {
        if(bits === undefined) {
            bits = 2;
        }
        if(!num) {
            num = "0.0";
        }
        let v = parseFloat(num);
        if(v === 0.0) {
            return "0%"
        }
        v *= 100;
        return v.toFixed(bits) + '%';
    },
    beautifyNumValue(num, bits) {
        if(bits === undefined) {
            bits = 4;
        }
        if(!num) {
            num = "0.0";
        }
        let v = parseFloat(num);
        if(v === 0.0) {
            return "0"
        }
        return v.toFixed(bits);
    }
}

export default Utils;