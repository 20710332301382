import agent from '../../ajax/agent'
import { userStore } from '../../mobx';
import { message } from 'antd';

class UserAction {
    login = (data) => {
        agent.post("/api/auth/login", data)
        .then(data => {
            userStore.setToken(data.data); 
            window.location = "/" 
        })
        .catch(e => {
            message.error("登录失败:" + e.message);
            console.error(e);
        })
    }

    logout = () => {
        agent.post("/api/auth/logout")
        .then(data => {
            userStore.setToken(); 
            window.location = "/login";
        })
        .catch(e => {
            userStore.setToken(); 
            window.location = "/login";
            //message.error("登出失败" + e.message);
            console.error(e);
        })
    }

    fetchIdentityCode = (mobile, type, captchaCode) => {
        const param = {type, mobile, captchaCode};
        agent.get('/api/webapp/user/identity_code', param)
            .then(data => {
                console.log(data);
            })
            .catch(e => {
                message.error(e.message);
                console.error(e);
            })
    }

    fetchInviteCode = () => {
        agent.get("/api/user/inviteCode")
            .then(data => {
                userStore.setInviteCode(data.data)
            })
            .catch(e => {
                message.error(e.message);
                console.error(e);
            })
    }

    fetchUserProfile = () => {
        Promise.all([
            agent.get("/api/user/profile"),
            agent.get("/api/user/permission"),
            agent.get("/api/user/device"),
            agent.get(`/api/user/role`)
        ])
        .then(([profileData, permissionData, deviceData, roleData]) => {
            userStore.setUserProfile(profileData.data);
            userStore.setUserPermission(permissionData.data);
            userStore.setUserDevice(deviceData.data);
            userStore.setUserRole(roleData.data);
        })
        .catch(e => {
            message.error("获取用户信息失败:" + e.message);
            console.error(e);
        })
    }
}

export default new UserAction();