import { observable, action } from "mobx";
import UserManageAction from './UserManageAction';

class UserManageStore {
    @observable userListData = {};

    @observable targetUserId;
    @observable userProfile = {};
    @observable userDevices = [];
    @observable userRoles = [];
    @observable userPermissions = [];

    @observable dayStatisticsData = {};
    @observable retentionRateData = {};

    @observable userActionBlock = {};

    @observable userActionBlockSuccess = null;

    @observable deleteUserDone = false;

    @action
    setDeleteUserDone(done) {
        this.deleteUserDone = done;
    }

    @action
    setUserWeixinInfo(info) {
        this.userWeixinInfo = info;
    }

    @action
    setTargetUserId(targetUserId) {
        if(this.targetUserId !== targetUserId) {
            UserManageAction.fetchUserProfile(targetUserId);
            this.targetUserId = targetUserId;
            this.userProfile = {};
            this.userDevices = [];
            this.userRoles = [];
            this.userPermissions = [];
        }
    }

    @action
    setDayStatisticsData(dayStatisticsData) {
        this.dayStatisticsData = dayStatisticsData;
    }

    @action
    setUserActionBlock(data) {
        this.userActionBlock = data || {};
        this.setUserActionBlockSuccess(true);
    }

    @action
    setUserActionBlockSuccess(success) {
        this.userActionBlockSuccess = success;
    }

    @action
    setDetentionRateData(retentionRateData) {
        this.retentionRateData = retentionRateData;
    }

    @action
    setUserList(userListData) {
        this.userListData = userListData;
    }

    @action
    setUserRole(roles) {
        this.userRoles = roles;
    }

    @action
    setUserPermission(perms) {
        this.userPermissions = perms;
    }

    @action
    setFetchUserRole(fetchUserRole) {
        this.fetchUserRole = fetchUserRole;
    }

    @action
    setUserProfile(userProfile) {
        this.userProfile = userProfile;
    }

    @action
    setUserDevice(devices) {
        this.userDevices = devices;
    }
}

export default UserManageStore;