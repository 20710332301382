import React, { Suspense } from 'react';
import Header from './component/common/Header';
import Footer from "./component/common/Footer";
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom';
import * as stores from './mobx';
import { Provider } from 'mobx-react';

const lazy = React.lazy;
const User = lazy(() => import('./component/user'));
const LoginForm = lazy(() => import('./component/login'));
const Product = lazy(() => import('./component/product'));
const Device = lazy(() => import('./component/device'));
const Firmware = lazy(() => import('./component/firmware'));
const Activity = lazy(() => import('./component/activity'));
const Interstitial = lazy(() => import("./component/interstitial"));
const About = lazy(() => import("./component/about"));
const Notice = lazy(() => import('./component/notice'));
const Main = lazy(() => import('./component/main'));

class App extends React.Component {
  render() {
    return (
      <Provider {...stores}>
        <BrowserRouter  basename="/">
          <Header/>
          <Suspense fallback="加载中...">
            <Switch>
              <Route path='/user' component={User} />
              <Route path='/login' component={LoginForm}/>
              <Route path='/product' component={Product} />
              <Route path='/device' component={Device} />
              <Route path='/firmware' component={Firmware} />
              <Route path='/activity' component={Activity} />
              <Route path='/notice' component={Notice} />
              <Route path='/interstitial' component={Interstitial} />
              <Route path='/about' component={About} />
              <Route path='/' component={Main} />
              <Redirect to='/' />
            </Switch>
          </Suspense>
          <Footer/>
        </BrowserRouter>
      </Provider>
    );
  }
}

export default App;
