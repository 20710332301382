import sevneblockLogo from '../../resources/img/sevenblock_logo.png'
import xingkuiLogo from '../../resources/img/xingkui-logo.png'
import sevneblockFavicon from '../../resources/img/favicon.ico'
import xingkuiFavicon from '../../resources/img/xingkui-favicon.png'

const brands = {
    "sevenblock": {
        title: "七块技术",
        favicon: sevneblockFavicon,
        logo: sevneblockLogo,
        slogen: "全球智能成人用品领导者"
    },
    "sexhande": {
        title: "斯汉德",
        favicon: sevneblockFavicon,
        logo: sevneblockLogo,
        slogen: "全球智能成人用品领导者"
    },
    "xingkui":  {
        title: "暮嗨",
        favicon: xingkuiFavicon,
        logo: xingkuiLogo,
        slogen: "大人の玩具は恥ずかしいものとされてきた そんな価値観に革新を性生活がもっと豊かに！"
    },
}

export default brands;