import axios from 'axios'
import {userStore, appChannelConstantStore} from '../mobx/'
import qs from 'qs'
import { message } from 'antd';

let instance = axios.create({
    timeout: 60000,
    baseURL: process.env.REACT_APP_BASE_URL,
    withCredentials: true
})

instance.interceptors.request.use((config) => {
    config.headers = {
        'Authorization': userStore.token,
        'x-channel-id': appChannelConstantStore.appChannelId,
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
        'withCredentials': true
    };
    //console.error("http headers", config.headers)
    return config;
}, (error) => {
    return Promise.reject(error);
});

// Add a response interceptor
instance.interceptors.response.use((response) => {
    if(response && response.data) {
        const {code} = response.data;
        if(code === 4002) {
            userStore.setToken(null);
            message.warn("Token已过期，请重新登录");
            window.location.href = '/login';
        }
    }

    return response;
}, (error) => {
    // 可以在后面的请求中catch
    console.error("interceptors response error")
    return Promise.reject(error);
});

const requests = {
    get: (url, params) => 
        instance.get(url,
        {
            params: params
        })
        .then(res => {
            if(res.data.code !== 1000) {
                throw new Error(res.data.message);
            }
            return res.data;
        }),
        
    post: (url, data) =>
        instance.post(url, qs.stringify(data))
        .then(res => {
            if(res.data.code !== 1000) {
                if(res.data.code === 5056) {
                    userStore.setLoginIdentityCode(true);
                }
                throw new Error(res.data.message);
            }
            return res.data
        }),
    
    delete: (url, data) =>
        instance.delete(url, data)
        .then(res => {
            if(res.data.code !== 1000) {
                throw new Error(res.data.message);
            }
            return res.data
        }),
}

export default requests;
