import { observable, action } from "mobx";
import AppChannelConstant from './AppChannelConstant';

class AppChannelConstantStore {
    @observable appChannelConstant = {};
    @observable appChannelId = "sevenblock";

    @action
    setChannelId(channelId) {
        this.appChannelId = channelId;
        this.appChannelConstant = AppChannelConstant[channelId];
    }
}

export default AppChannelConstantStore;