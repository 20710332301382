import React from 'react';

import { inject, observer } from 'mobx-react';
import appChannelAction from "../app-channel/AppChannelAction";
import { NavLink, Link, withRouter } from "react-router-dom";
import userAction from "../user/UserAction";
import { Menu } from 'antd';
import style from './Header.module.css';

const SubMenu = Menu.SubMenu;

@withRouter
@inject("appChannelConstantStore", "userStore")
@observer
class Header extends React.Component {
    componentDidMount() {
        appChannelAction.fetchAppChannel();
        if(this.props.userStore.token) {
            userAction.fetchUserProfile();
        }
    }
    getMenuContent() {
        const menus = [
            {
                mid: '/',
                title: "首页"
            },
            {
                mid: '/user',
                title: '用户',
                submenu: [
                    {url: `/user`, name: '用户列表', iconType: 'user'},
                    {url: `/user/all/retention_rate`, name: '留存统计', iconType: 'crown'},
                    {url: `/user/all/active_statistic`, name: '活跃统计', iconType: 'crown'},
                ]
            },
            {
                mid: '/product',
                title: '产品',
                submenu: [
                    {url: '/product', name: '产品列表', iconType: 'unordered-list'},
                    {url: '/product/compose', name: '产品编辑', iconType: 'edit'},
                ]
            },
            {
                mid: '/device',
                title: '设备',
            },
            /*
            {
                mid: '/job',
                title: '任务'
            },
            {
                mid: '/property',
                title: '属性',
                submenu: [
                    {url: '/property', name: '属性列表', iconType: 'unordered-list'},
                    {url: '/property/compose', name: '属性编辑', iconType: 'edit'},
                ]
            },
            */
            {
                mid: '/firmware',
                title: '固件',
                submenu:[
                    {url: "/firmware", name: '固件列表', iconType: 'unordered-list'},
                    {url: "/firmware/compose", name: '固件编辑', iconType: 'edit'},
                ]
            },
            {
                mid: '/activity',
                title: '活动',
                submenu: [
                    {url: "/activity", name: '普通活动', iconType: 'unordered-list'},
                    {url: "/activity/compose", name: "编辑活动", iconType: 'edit'},
                ]
            },
            {
                mid: '/notice',
                title: '公告',
                submenu: [
                    {url: "/notice", name: '公告列表', iconType: 'unordered-list'},
                    {url: "/notice/compose", name: "编辑公告", iconType: 'edit'},
                ]
            },
            {
                mid: '/interstitial',
                title: '插屏广告',
                submenu: [
                    {url: "/interstitial", name: '插屏广告列表', iconType: 'unordered-list'},
                    {url: "/interstitial/compose", name: "编辑插屏广告", iconType: 'edit'},
                ]
            },
            {
                mid: '/about',
                title: '关于'
            },
        ]
        return <Menu selectedKeys={[this.props.location.pathname]} mode="horizontal" theme='dark' multiple={true}>
            {menus.map(m => {
                if(m.submenu) {
                    return <SubMenu key={m.mid} title={<span>{m.title}</span>}>
                        {
                            m.submenu.map( sm => {
                                return <Menu.Item key={sm.url}><NavLink to={sm.url}>{sm.name}</NavLink></Menu.Item>
                            })
                        }
                  </SubMenu>
                } else {
                    return <Menu.Item key={m.mid}>
                        <NavLink to={m.mid}>{m.title}</NavLink>
                    </Menu.Item>
                }
            })}
        </Menu>
    }

    render() {
        const {appChannelConstant} = this.props.appChannelConstantStore;
        const {profile, token} = this.props.userStore;
        if(this.props.location.pathname === '/login') {
            return "";
        }
        return (<div>
            <div className={style.brand_user}>
            <span >{<img className={style.logo} src={appChannelConstant.favicon} alt='logo'/>}<span>&nbsp;{appChannelConstant.slogen}</span></span>
            {
                !token ? 
                <span className={style.login}>{`欢迎访问${appChannelConstant.title}管理系统, 请`}<Link to="/login">登录</Link></span>
                :
                <span className={style.login}>欢迎您,<b>{profile.nickname}</b>&nbsp;<Link to='/' onClick={() => {userAction.logout();}}>退出</Link></span>
            }
            </div>
            {this.getMenuContent()}
        </div>)
    }
}
export default Header;