import { observable, action } from "mobx";

class ActivityStore {
    @observable activityData = {};

    @observable targetActivity = {};

    @observable refetch = false;

    @action
    setActivityData(activityData) {
        this.activityData = activityData;
    }

    @action
    setTargetActivity(targetActivity) {
        this.targetActivity = targetActivity;
    }
    
    @action
    setRefetch(refetch) {
        this.refetch = refetch;
    }
}

export default ActivityStore;