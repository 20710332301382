import { observable, action } from "mobx";

class InterstitialStore {
    @observable interstitialData = {};

    @observable targetInterstitial;

    @observable refetch = false;

    @action
    setInterstitial(interstitialData) {
        this.interstitialData = interstitialData;
    }

    @action
    setTargetInterstitial(targetInterstitial) {
        this.targetInterstitial = targetInterstitial;
    }
    
    @action
    setRefetch(refetch) {
        this.refetch = refetch;
    }
}

export default InterstitialStore;