import UserStore from "../component/user/UserStore";
import UserManageStore from '../component/user/UserManageStore'
import { configure, makeAutoObservable } from "mobx";
import RouterStore from "./RouterStore";
import JobManageStore from "../component/job/JobManageStore";
import ProductStore from "../component/product/ProductStore";
import SystemNoticeStore from "../component/notice/SystemNoticeStore";
import AliyunOssStore from "../component/firmware/AliyunOssStore";
import UserDeviceStore from "../component/device/UserDeviceStore";
import FirmwareStore from "../component/firmware/FirmwareStore";
import ActivityStore from "../component/activity/ActivityStore";
import PropertyStore from "../component/property/PropertyStore";
import InterstitialStore from "../component/interstitial/InterstitialStore";
import AppChannelConstantStore from "../component/app-channel/AppChannelConstantStore";

configure({enforceActions: 'always'})

let userStore = makeAutoObservable(new UserStore());
let routerStore = makeAutoObservable(new RouterStore());
let userManageStore = makeAutoObservable(new UserManageStore());
let jobManageStore = makeAutoObservable(new JobManageStore());
let productStore = makeAutoObservable(new ProductStore());
let systemNoticeStore = makeAutoObservable(new SystemNoticeStore());
let aliyunOssStore = makeAutoObservable(new AliyunOssStore());
let userDeviceStore = makeAutoObservable(new UserDeviceStore());
let firmwareStore = makeAutoObservable(new FirmwareStore());
let activityStore = makeAutoObservable(new ActivityStore());
let propertyStore = makeAutoObservable(new PropertyStore());
let interstitialStore = makeAutoObservable(new InterstitialStore());
let appChannelConstantStore = makeAutoObservable(new AppChannelConstantStore());

export {
    userStore, 
    routerStore, 
    userManageStore,
    jobManageStore,
    productStore,
    systemNoticeStore,
    aliyunOssStore,
    userDeviceStore,
    firmwareStore,
    activityStore,
    propertyStore,
    interstitialStore,
    appChannelConstantStore
};