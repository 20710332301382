import { observable, action } from "mobx";

export default class ProductStore {
    @observable productListData;

    @observable targetProduct = null;

    @observable productSnRangeList = [];

    @observable targetProductSnRange = {};

    @observable fetchProductSnRange = false;

    @observable fetchProduct = false;

    @action
    setFetchProduct(fetch) {
        this.fetchProduct = fetch;
    }

    @action
    setFetchProductSnRange(fetch) {
        this.fetchProductSnRange = fetch;
    }

    @action
    setProductListData(productListData) {
        this.productListData = productListData;
    }

    @action
    setTargetProduct(product) {
        this.targetProduct = product;
    }

    @action
    setTargetProductSnRange(snRange) {
        this.targetProductSnRange = snRange;
    }

    @action
    setProductSnRange(productSnRangeList) {
        this.productSnRangeList = productSnRangeList;
    }
}