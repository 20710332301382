import Utils from '../../common/Utils';
import { appChannelConstantStore } from '../../mobx/'

function fetchAppChannel() {
    //let channelId = Utils.getCookie("x-channel-id");
    //https://xingkui.social.prod.sevenblock.tech/download
    debugger
    //let pattern = /(.*).social.(.*).sevenblock.tech/;
    //manage
    let pattern = /(.*).manage.(.*).sevenblock.tech/;
	let content = window.location.host;
    let result = pattern.exec(content);
    let channelId;
    if(result && result.length > 0) {
        channelId = (result[1].trim());
    } 
    debugger
    appChannelConstantStore.setChannelId(channelId || "sevenblock");
}
const obj = {
    fetchAppChannel,
}
export default obj;