import { observable, action } from "mobx";

export default class UserAccountStore {
    @observable userDeviceData = [];
    @observable managerBindSuccess = false;
    @observable allDeviceData = {};

    @action
    setUserDevice(userDeviceData) {
        this.userDeviceData = userDeviceData;
    }

    @action
    setAllDeviceData(allDeviceData) {
        this.allDeviceData = allDeviceData;
    }

    @action
    setManagerBindSuccess(success) {
        this.managerBindSuccess = success;
    }
}